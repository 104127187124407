export function cookiebotScript ({ brand, market }) {
  if (!brand || !market) return

  const ids = {
    zmarta: {
      se: '331c262e-2f29-4826-a52d-058fec8d2690',
      no: '7546b09d-7e36-4fa2-8f2c-c64f7cb22190',
      fi: '91490bf6-aa44-4cf8-82b2-86b161236965'
    },
    broker: {
      se: '628e1df0-6019-4553-9868-d98f0b27e9d7',
      no: '0f63109d-29b8-46c2-a890-edd514dc141c',
      fi: 'acc985de-bc54-47a3-86d4-3de75dbc09c4'
    }
  }

  return {
    scripts: [
      {
        vmid: 'cookiebot-script',
        type: 'text/javascript',
        once: true,
        async: true,
        defer: true,
        body: true,
        src: `https://consent.cookiebot.com/uc.js?cbid=${ids[brand]?.[market]}`
      },
      {
        vmid: 'cookiebot-callback-script',
        type: 'text/javascript',
        once: true,
        async: true,
        defer: true,
        body: true,
        innerHTML: `
          function CookiebotCallback_OnAccept() {
            if (Cookiebot.consent.preferences) dataLayer.push({ event: 'cookieconsent_preferences' });
            if (Cookiebot.consent.statistics) dataLayer.push({ event: 'cookieconsent_statistics' });
            if (Cookiebot.consent.marketing) dataLayer.push({ event: 'cookieconsent_marketing' });
          }
        `
      }
    ],
    noscripts: [],
    disableSanitizers: {
      'cookiebot-callback-script': ['innerHTML']
    }
  }
}
